import React, { useEffect, useState } from "react";
import { CLOSE_SUB_ADMIN_DIALOGUE } from "../../store/subAdmin/types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { getCoinSellerUniqueId } from "../../store/coinSeller/action";
import { addSubAdmin } from "../../store/subAdmin/action";

const SubAdminDialog = () => {
  const { dialog: open, dialogData } = useSelector((state) => state.subAdmin);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [role, setRole] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const dispatch = useDispatch();

  const [errors, setError] = useState({
    email: "",
    password: "",
    role: "",
  });

  useEffect(
    () => () => {
      setError({
        email: "",
        password: "",
        role: "",
      });
      setEmail("");
      setPassword("");
      setRole("");
    },
    [open]
  );

  const userRole = [
    { name: "User", path: "/admin/user", icon: "users" },
    { name: "Banner", path: "/admin/banner", icon: "image" },
    {
      name: "Pending Complain",
      path: "/admin/pendingComplain",
      icon: "layout",
    },
    { name: "Gift Category", path: "/admin/giftCategory", icon: "clock" },
    { name: "Gift", path: "/admin/gift", icon: "clock" },
    { name: "Reaction", path: "/admin/reaction", icon: "clock" },
    { name: "Entry Effect", path: "/admin/entryEffect", icon: "clock" },
    { name: "Avtar Frame", path: "/admin/avatarFrame", icon: "clock" },
    { name: "Sticker", path: "/admin/sticker", icon: "clock" },
    { name: "Theme", path: "/admin/theme", icon: "clock" },
    { name: "Post", path: "/admin/post", icon: "clock" },
    { name: "Song", path: "/admin/song", icon: "clock" },
    { name: "Hashtag", path: "/admin/hashtag", icon: "clock" },
    { name: "Fake Comment", path: "/admin/comment", icon: "clock" },
    { name: "Agency", path: "/admin/agency", icon: "user-plus" },
    { name: "Host Request", path: "/admin/hostRequest", icon: "user-check" },
    { name: "Coin Seller", path: "/admin/coinSeller", icon: "user-check" },
    {
      name: "Coin Plan History",
      path: "/admin/coinPlan/history",
      icon: "clock",
    },
    {
      name: "VIP Plan History",
      path: "/admin/vipPlan/history",
      icon: "clock",
    },

    { name: "Host Request", path: "/admin/hostRequest", icon: "dollar-sign" },
    {
      name: "User Redeem Request",
      path: "/admin/userRedeemRequest",
      icon: "dollar-sign",
    },
    {
      name: "Host Settlement",
      path: "/admin/hostpending",
      icon: "dollar-sign",
    },
    {
      name: "Agency Settlement",
      path: "/admin/pending",
      icon: "dollar-sign",
    },
    { name: "Game History", path: "/admin/gameHistory", icon: "dollar-sign" },
    { name: "Level", path: "/admin/level", icon: "dollar-sign" },
    { name: "Video", path: "/admin/video", icon: "dollar-sign" },
    { name: "Reported User", path: "/admin/reportedUser", icon: "dollar-sign" },
    { name: "Coin Plan", path: "/admin/coinPlan", icon: "dollar-sign" },
    { name: "Vip Plan", path: "/admin/vipPlan", icon: "dollar-sign" },
  ];

  function onSelect(selectedList, selectedItem) {
    paymentGateway.push(selectedItem);
  }

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setPaymentGateway(selectedList.map((data) => data.name));
  }

  const closePopup = () => {
    dispatch({ type: CLOSE_SUB_ADMIN_DIALOGUE });
  };

  const handleSubmit = () => {
    debugger;
    if (!email || !password || paymentGateway?.length <= 0) {
      let error = {};

      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "Password Is Required !";
      if (paymentGateway?.length <= 0) error.role = "Role Is Required";
      return setError({ ...error });
    } else {
      const data = {
        email,
        password: parseInt(password),
        roles: paymentGateway,
      };

      console.log("data", data);

      dispatch(addSubAdmin(data));
      closePopup();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        // maxWidth="400px"
        sx={{ maxWidth: "400px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Sub Admin </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            email: "email can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            email: "",
                          });
                        }
                      }}
                    />
                    {errors.email && (
                      <div className="ml-2 mt-1">
                        {errors.email && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.email}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div class="row d-flex mt-3">
                    <div class={`col-md-12`}>
                      <div class="form-group">
                        <label class="mb-2 text-gray">Password</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);

                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                password: "password can't be a blank!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                password: "",
                              });
                            }
                          }}
                        />
                        {errors.password && (
                          <div className="ml-2 mt-1">
                            {errors.password && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.password}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <label for="googlePlayEmail" class="form-label">
                      Roles
                    </label>

                    <Multiselect
                      options={userRole}
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                    />
                    {errors.role && (
                      <div className="ml-2 mt-1">
                        {errors.role && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.role}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SubAdminDialog;
